import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Loader from './Loader/Loader';
import axios from "axios";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import authHeader from '../services/auth-header';
import { useHistory } from 'react-router-dom';
import { refreshToken } from '../utils/auth';
import { logout } from '../utils/auth';

const useStyles = makeStyles(theme => ({
    followingTitleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: 'space-between'
    },
    spacingTop: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10)
    },
    suggestingAccountsList: {
      listStyle: 'none',
      margin: '0',
      padding: '0'
    },
    suggestingAccountsListItem: {
      padding: '15px',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'no-wrap',
      justifyContent: 'space-between',
      margin: '15px 0',
      boxShadow: '1px 1px 10px 1px silver',
      borderRadius: '10px',
      '&:hover': {
        boxShadow: '1px 3px 10px 1px silver'
     }
    },
    suggestingAccountsAddress: {
      marginTop: '8px'
    },
    suggestingAccountsIcon: {
      marginTop: '8px'
    },
    btnText: {
      marginTop: '4px',
      marginLeft: '4px'
    }
}));

function SuggestingAccounts(props) {
    const { addAccount, setAddAccount, accountRemoved } = props;
    const classes = useStyles();
    const history = useHistory();

    const [suggistingAccounts, setSuggistingAccounts] = useState([]);
    // const [followingAccountList, setFollowingAccountList] = useContext(AccountsContext);
    const [loading, setLoading] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [foundSuggestingAccounts, setFoundSuggestingAccounts] = useState(true);

    /**
     * Handle adding suggisting accounts
     * @param {*} account 
     */
    const handleAddSuggistingAccounts = async (account) => {
        const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
        const addAccountAPIEndPoint = '/api/v1/follow-account';
        const endPoint = baseUrl + addAccountAPIEndPoint;
        let authorizationHeader = authHeader();
        let addAccountAPIResponse;
        setLoading(true);
        setSelectedAccount(account);
    
        try {
          addAccountAPIResponse = await axios({
            url: endPoint,
            method: 'POST',
            headers: authorizationHeader,
            data: {
              account
            }
          });
        } catch(err) {
          // setLoading(false);
          console.log("Error occured in add new account: ", err);
          if(err.response.status === 401){
            try {
              await refreshToken();
  
              authorizationHeader = authHeader();
              addAccountAPIResponse = await axios({
                url: endPoint,
                method: 'POST',
                headers: authorizationHeader,
                data: {
                  account
                }
              });
            } catch (err) {
              if(err.status === 403){
                setLoading(false);
                if(err.response.status === 403) logout(history);
              }
            }
          }
        }

        if(addAccountAPIResponse.status === 200) {
          alert('Successfully account added');
          setAddAccount(!addAccount);
          setLoading(false);
    
        } else {
          setLoading(false);
          alert(addAccountAPIResponse.data.message);
        }
    }
    
    useEffect(() => {
        const getSuggistingAccounts = async () => {
          const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
          const followingAccountsAPIEndPoint = '/api/v1/suggesting-accounts';
          const endPoint = baseUrl + followingAccountsAPIEndPoint;
          let authorizationHeader = authHeader();
    
          let apiResponse;
          try{
            apiResponse = await axios({
              url: endPoint,
              method: 'POST',
              headers: authorizationHeader
            });
          }
          catch(err) {
            console.log("Error: ", err.response.data, err.response.status);
            if(err.response.status === 401){
              try {
                await refreshToken();
    
                authorizationHeader = authHeader();
                apiResponse = await axios({
                  url: endPoint,
                  method: 'POST',
                  headers: authorizationHeader
                });
              } catch (err) {
                if(err.response.status === 403) logout(history);
              }
            }
          }

          if(apiResponse.status === 200) {
            if(apiResponse.data.suggestingAccounts.length > 0 ) {
              setSuggistingAccounts(apiResponse.data.suggestingAccounts);
              setFoundSuggestingAccounts(true);
            }
            else {
              setFoundSuggestingAccounts(false);
            }
          }

        }

        getSuggistingAccounts();

    }, [addAccount, accountRemoved]);

    if(!foundSuggestingAccounts) {
      return <div>Not Found</div>
    }

    return (
      <>
        { 
            suggistingAccounts.length > 0 ? <ul className={classes.suggestingAccountsList}>
            {
                suggistingAccounts.map((account, index) => <li key={index} className={classes.suggestingAccountsListItem}>
                    <div className={classes.suggestingAccountsIcon}>
                    <FontAwesomeIcon icon="circle-user" size="xl" />
                    </div>
                    <div className={classes.suggestingAccountsAddress}>
                    { account }
                    </div>
                    <Button onClick={e => handleAddSuggistingAccounts(account)}  variant="outlined" color="primary" size="small">
                    { loading && selectedAccount === account ? <CircularProgress size={25} thickness={4} color="primary" /> : <FontAwesomeIcon icon="circle-plus" size="lg" /> }
                    <span className={classes.btnText}>Add</span>
                    </Button>
                </li>) 
            }
            </ul> : <Loader />
        }
    </>
    )
}

export default SuggestingAccounts