import { ethers } from 'ethers';

export const getSigner = async () => {
    await window.ethereum.enable();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner();
    return signer;
}

export const getAccountAddress = async () => {
    const signer = await getSigner();
    const accountAddress = await signer.getAddress();
    return accountAddress;
}

export const getSignedValue = async (value) => {
    const signer = await getSigner();
    const signValue = await signer.signMessage(value);
    return signValue;
}