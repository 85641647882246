import React, {useState, useEffect} from "react";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { getAccountAddress, getSignedValue } from "../utils/wallet";
import { generateRandom } from "../utils/random";
import authHeader from "../services/auth-header";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Logo from "../components/Logo";

import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  field: {
    marginTop: 10,
    marginBottom: 5,
    display: 'block'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(3)
  },
  submit: {
    marginTop: theme.spacing(2)
  },
  formContainer: {
    marginTop: theme.spacing(10)
  },
  loadingSpiner: {
    color: "#FFF",
    marginRight: "10px"
  },
  metaMaskLoginBtn: {
    marginTop: theme.spacing(1)
  },
  formContainer: {
    marginTop: theme.spacing(8)
  },
  followAccount: {
    marginTop: theme.spacing(8),
    textAlign: "center",
    fontSize: '1.3em',
    fontFamily: "Times New Roman, Times, serif"
  },
  followAccountText: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2)
  },
  followAccountAddress: {
    fontSize: '1em',
    fontFamily: "Times New Roman, Times, serif"
  }
}));

export default function SignIn () {
  const classes = useStyles();
  let history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [metamaskLoading, setMetamaskLoading] = useState(false);
  const [followAccountAddress, setFollowAccountAddress] = useState('');
  const [isSetFollowAccount, setIsSetFollowAccount] = useState(false);

  /**
   * Handle user signin
   * @param {*} e 
   */
  const handleSignIn = async(e) => {
    e.preventDefault();
    let checkFlag = true;
    setLoading(true);
    setEmailError(false);
    setPasswordError(false);

    if(email === '' || email.length < 6) {
      // Validation should be added
      setEmailError(true);
      checkFlag = false;
    }

    if(password.length < 5 ) {
      setPasswordError(true);
      checkFlag = false;
    }

    if(checkFlag) {
      // Successfully passed all validation
      const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
      const signInAPIEndPoint = '/api/v1/login';
      const endPoint = baseUrl + signInAPIEndPoint;
      let signInApiResponse;
      try {
          signInApiResponse = await axios.post(endPoint, {
            email: email,
            password: password
        });
      } catch(err) {
          console.log("Login Failed: ", err);
          setLoading(false);
          if(err.response.status === 401) {
            alert(err.response.data.message);
          }
      }
      
      if(signInApiResponse) {
        if(signInApiResponse.status === 200) {
          localStorage.setItem('nfty-social-access-token', JSON.stringify(signInApiResponse.data.token));
          localStorage.setItem('nfty-social-refresh-token', JSON.stringify(signInApiResponse.data.refreshToken));

          if(isSetFollowAccount){
            await followAccount(followAccountAddress);
          }
          // alert(signInApiResponse.data.message);
          setLoading(false);
          history.push('/');
        }
      }
    }
    else {
      setLoading(false);
    }
  }

  /**
   * Handle add an account to follow
   * @param {*} accountAddress 
   */
   const followAccount = async (account) => {
    let followAccountResponse;
    const authorizationHeader = authHeader();
    try {
      followAccountResponse = await axios({
        url: process.env.REACT_APP_API_SERVER_BASE_URL + "/api/v1/follow-account",
        method: 'POST',
        headers: authorizationHeader,
        data: {
          account
        }
      });
    } catch(err) {
      console.log("Error occured in add new account: ", err);
      setLoading(false);
      if(err.response.status === 409) {
        alert("Follow account already exists.");
      }
      
    }
    if(followAccountResponse.status === 200) {
      console.log("Follow account success");
    }
    else{
      console.log("Failed to add follow account");
      alert("Failed to add follow account");
    }
  }

  /**
   * Enable login functionalities with metamask
   * @param {*} event 
   */
  const handleMetamaskLogin = async (event) => {
    event.preventDefault();
    setMetamaskLoading(true);
    const accountAddress = await getAccountAddress();
    const nonce = generateRandom();
    const signature = await getSignedValue(nonce);

    const endPoint = process.env.REACT_APP_API_SERVER_BASE_URL + "/api/v1/login-with-metamask";
    try {
      const response = await axios.post(endPoint, {
        walletAddress: accountAddress,
        signature: signature,
        nonce: nonce
      });

      if(response.status === 202) {
        history.push({
          pathname: '/metamask-signup',
          state: {
            walletAddress: accountAddress,
            signature: signature,
            nonce: nonce
          }
        });
      }
      if(response.status===200) {
        
        if(isSetFollowAccount) 
          await followAccount(followAccountAddress);
        localStorage.setItem('nfty-social-access-token', JSON.stringify(response.data.token));
        localStorage.setItem('nfty-social-refresh-token', JSON.stringify(response.data.refreshToken));
        // alert(signInApiResponse.data.message);
        setMetamaskLoading(false);
        history.push('/');

      }
    } 
    catch(err) {
        console.log("Error in Metamask login: ", err);
        setMetamaskLoading(false);
    }
  }

  useEffect(() => {
    if(typeof location.state !== 'undefined') {
      setFollowAccountAddress(location.state.followAccount);
      setIsSetFollowAccount(true);
    }
  }, [location]);

  return (
    <>
      <Container component="main" maxWidth="xs">

        <CssBaseline />
        <Logo />
      </Container>

      {
        isSetFollowAccount ? <div className={classes.followAccount}>
            <span className={classes.followAccountText}>Sign in to follow: </span>
            <span className={classes.followAccountAddress}>
              {followAccountAddress}
            </span>
        </div> : <></>
      }

      <Container component="main" maxWidth="xs">
            {/* <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
            <div className={classes.formContainer}>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSignIn}>
                <TextField
                  onChange={(e)=> setEmail(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={emailError}
                />
                <TextField
                  onChange={(e)=> setPassword(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={passwordError}
                />
                
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  { loading ? <CircularProgress size={25} className={classes.loadingSpiner} /> : <></> }
                  Sign In
                </Button>
                
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.metaMaskLoginBtn}
                  onClick={e => handleMetamaskLogin(e)}
                >
                  { metamaskLoading ? <CircularProgress size={25} className={classes.loadingSpiner} /> : <></> }
                  Login With Metamask
                </Button>
                
                <Grid container>
                  <Grid item>
                    <Link href="/signup" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
      </Container>
    </>
  );
};

