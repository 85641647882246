import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";
import Logo from "../components/Logo";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import authHeader from "../services/auth-header";

// function MadeWithLove() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {"Built with love by the "}
//       <Link color="inherit" href="https://material-ui.com/">
//         NFTY Social
//       </Link>
//       {" team."}
//     </Typography>
//   );
// }


const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  field: {
    marginTop: 10,
    marginBottom: 5,
    display: 'block'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  loadingSpiner: {
    color: "#FFF",
    marginRight: "10px"
  },
  formContainer: {
    marginTop: theme.spacing(8)
  },
  followAccount: {
    marginTop: theme.spacing(8),
    textAlign: "center",
    fontSize: '1.3em',
    fontFamily: "Times New Roman, Times, serif"
  },
  followAccountText: {
    fontWeight: 'bold',
    marginRight: theme.spacing(2)
  },
  followAccountAddress: {
    fontSize: '1em',
    fontFamily: "Times New Roman, Times, serif"
  },
}));

export default function SignUp() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword,setConfirmPassword]= useState('');
  const [userNameError, setUserNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [followAccountAddress, setFollowAccountAddress] = useState('');
  const [isSetFollowAccount, setIsSetFollowAccount] = useState(false);

  /**
   * Handle user input,
   * @param {*} event 
   * @returns 
   */
  const handleInputChange = (event) => {
    const inputName = event.target.name;
    const value = event.target.value;

    if(userNameError || passwordError || confirmPasswordError || emailError) {
      setConfirmPasswordError(false);
      setPasswordError(false);
      setEmailError(false);
      setUserNameError(false)
    }

    switch(inputName) {
      case 'userName':
        setUserName(value);
        return;
      case 'email':
        setEmail(value);
        return;
      case 'password':
        setPassword(value);
        return;
      case 'confirmPassword':
        setConfirmPassword(value);
        return;
      default:
        return ;
    }
  }

  /**
   * Handle add an account to follow
   * @param {*} accountAddress 
   */
  const followAccount = async (account) => {
    let followAccountResponse;
    const authorizationHeader = authHeader();
    try {
      followAccountResponse = await axios({
        url: process.env.REACT_APP_API_SERVER_BASE_URL + "/api/v1/follow-account",
        method: 'POST',
        headers: authorizationHeader,
        data: {
          account
        }
      });
    } catch(err) {
      console.log("Error occured in add new account: ", err);
      setLoading(false);
    }
    if(followAccountResponse.status === 200) {
      console.log("Follow account success");
    }
  } 

  /**
   * Handle user signup
   * @param {*} e 
   */
  const handleSignUp = async(e) => {
    e.preventDefault();
    let checkFlag = true;
    setLoading(true);

    if(userName === '' || userName.length < 4) {
      setUserNameError(true);
      checkFlag = false;
    }
    if(email === '' || email.length < 6) {
      // Validation should be added
      setEmailError(true);
      checkFlag = false;
    }

    if(password.length < 5 ) {
      setPasswordError(true);
      checkFlag = false;
    }

    if(confirmPassword.length < 5 || confirmPassword !== password) {
      setConfirmPasswordError(true);
      checkFlag = false;
    }
    
    if(checkFlag) {
      // Successfully passed all validation
      const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
      const signUpAPIEndPoint = '/api/v1/signup';
      const endPoint = baseUrl + signUpAPIEndPoint;
      try {
        let signUpApiResponse = await axios.post(endPoint, {
            name: userName,
            email: email,
            password: password
        });
        if(signUpApiResponse.status === 200) {
          setLoading(false);
          // alert(signUpApiResponse.data.message);

          // Call login API
          const signInAPIEndPoint = '/api/v1/login';
          const endPoint = baseUrl + signInAPIEndPoint;
          let signInApiResponse;
          try {
            signInApiResponse = await axios.post(endPoint, {
              email: email,
              password: password
          });
          } catch(err) {
              console.log("Login Failed: ", err);
              setLoading(false);
              if(err.response.status === 401) {
                alert(err.response.data.message);
              }
          }
          if(signInApiResponse) {
            if(signInApiResponse.status === 200) {
              localStorage.setItem('nfty-social-access-token', JSON.stringify(signInApiResponse.data.token));
              localStorage.setItem('nfty-social-refresh-token', JSON.stringify(signInApiResponse.data.refreshToken));

              if(isSetFollowAccount) {
                await followAccount(followAccountAddress);
              }
              setLoading(false);
              history.push('/');
            }
          }
        }
        else{
          setLoading(false);
          alert(signUpApiResponse.data.message);
        }
      }
      catch(err) {
        setLoading(false);
        console.log("Error while sign up: ", err);
        alert("Sign Up failed.");
      }
    }
    else {
      setLoading(false);
    }

  }

  useEffect(() => {
    if(typeof location.state !== 'undefined') {
      setFollowAccountAddress(location.state.followAccount);
      setIsSetFollowAccount(true);
    }
  }, [location]);

  return (
    <>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {/* <div className={classes.paper}> */}
      <Logo />
    </Container>

    {
      isSetFollowAccount ? <div className={classes.followAccount}>
          <span className={classes.followAccountText}>Sign up to follow: </span>
          <span className={classes.followAccountAddress}>
            {followAccountAddress}
          </span>
      </div> : <></>
    }

    <Container component="main" maxWidth="xs">
      <div className={classes.formContainer} >
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSignUp}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                onChange={e => handleInputChange(e)}
                className={classes.field}
                variant="outlined"
                required
                fullWidth
                id="userName"
                label="User Name"
                name="userName"
                autoComplete="off"
                error={userNameError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={e => handleInputChange(e)}
                className={classes.field}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={emailError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={e => handleInputChange(e)}
                className={classes.field}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
                error={passwordError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={e => handleInputChange(e)}
                className={classes.field}
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="off"
                error={confirmPasswordError}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            { loading ? <CircularProgress size={25} className={classes.loadingSpiner} /> : <></> }
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link onClick={e => history.push({pathname: '/signin', state: {followAccount: followAccountAddress} })} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      {/* </div> */}
      {/* <Box mt={5}>
        <MadeWithLove />
      </Box> */}
    </Container>
  </>
  );
}
