import React, { useEffect, useState } from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import authHeader from '../services/auth-header';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { logout } from '../utils/auth';
import { useHistory } from 'react-router-dom';
import { refreshToken } from '../utils/auth';

const useStyles = makeStyles(theme => ({
  loadingSpiner: {
    color: "#FFF",
    marginRight: "10px"
  }
}));

function AddAccount({ addAccount, setAddAccount }) {
    const classes = useStyles();
    const history = useHistory();
    const [account, setAccount] = useState('');
    const [accountError, setAccountError] = useState(false);
    const [loading, setLoading] = useState(false);

    const validateAccount = (account) => {
      let isValid = false;
      // const isValidDomainRegex = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/;
      const ensDomainExt = account.trim().slice(-4);
      const validEnsExtensions = ['.io', '.org', '.com', '.alt', '.app', '.eth', '.xyz'];
      let isEnsDomain = false;
      validEnsExtensions.forEach(ext => {
          if(ensDomainExt.includes(ext)) {
              isEnsDomain = true;
          }
      });
      if (isEnsDomain) {
        // ENS name validations
          isValid = true;
      } else if(account.length === 42) {
          // max address lenght 42 char,
          // address prefix must be 0x
          if(account.slice(0, 2) === '0x') {
            isValid = true;
          }
      }
      return isValid;
    }

    const handleSetInput = (event) => {
      event.preventDefault();
      setAccountError(false);
      const name = event.target.name;
      const value = event.target.value;

      switch(name) {
        case 'account':
          setAccount(value);
          break;
        default:
          break;
      }
      return ;
    }

    const handleAddNewAccount = async(e) => {
      e.preventDefault();
      setLoading(true);
      // Ethereum account validation required
      const checkFlag = validateAccount(account);
      if(!checkFlag) {
        setAccountError(true);
        setLoading(false);
      }

      if(checkFlag) {
        const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
        const addAccountAPIEndPoint = '/api/v1/follow-account';
        const endPoint = baseUrl + addAccountAPIEndPoint;
        let authorizationHeader = authHeader();
        let addAccountAPIResponse;
        try {
          addAccountAPIResponse = await axios({
            url: endPoint,
            method: 'POST',
            headers: authorizationHeader,
            data: {
              account
            }
          });
        }catch(err) {
          console.log("Error occured in add new account: ", err);
          if(err.response.status === 401) {
            try {
              await refreshToken();
  
              authorizationHeader = authHeader();
              addAccountAPIResponse = await axios({
                url: endPoint,
                method: 'POST',
                headers: authorizationHeader,
                data: {
                  account
                }
              });
            } catch (err) {
              if(err.response.status === 403) logout(history);
            }
          }
          setLoading(false);
        }
        if(addAccountAPIResponse.status === 200) {
          alert('Successfully account added');
          setAddAccount(!addAccount);
          setLoading(false);

        } else {
          alert(addAccountAPIResponse.data.message);
          setLoading(false);
        }
      }

    }
    return (
            <div>
            <Typography variant='h6'>Add an account to follow</Typography>
            <form noValidate onSubmit={handleAddNewAccount}>
            <TextField
              onChange={(e) => handleSetInput(e)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="account"
              label="Account address... 0x00....AB or ensname.eth"
              name="account"
              autoComplete="account"
              autoFocus
              error={accountError}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              { loading ? <CircularProgress className={classes.loadingSpiner} size={25} /> : <></>}
              Add
            </Button>
          </form>
          </div>
        );
}

export default AddAccount;