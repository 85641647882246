import React from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from '@material-ui/core';

const classes = {
    logo: {
      margin: 'auto',
      textAlign: 'center',
      maxWidth: '30%'
    }
  };


function Logo() {
    return (
        <Toolbar>
            <img src={'../nfty_logo.png'} style={classes.logo} alt="logo" />
        </Toolbar>
      )
}

export default Logo;
