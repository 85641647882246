import axios from "axios";

/**
 * Check if an user is logged in or not
 * @returns 
 */
export const isLoggedIn  = () => {

    const refreshToken = window.localStorage.getItem('nfty-social-access-token');
    if(refreshToken) 
        return true;
    else 
        return false;
}

/**
 * Handle logout
 * @returns 
 */
 export const logout  = (history) => {
    window.localStorage.clear();
    history.push('/signin');
}

/**
 * Refresh and generate new access token
 * @returns 
 */
export const refreshToken = async () => {
    const refreshToken = JSON.parse(window.localStorage.getItem('nfty-social-refresh-token'));

    let refreshTokenResponse;
    try {
        refreshTokenResponse = await axios.post(
            process.env.REACT_APP_API_SERVER_BASE_URL + "/api/v1/token", {
                refreshToken: refreshToken
            }
        );

    } catch(err) {
        console.log("Error while refresh token: ", err, );
        return Promise.reject(err);
    }
    if(refreshTokenResponse.status === 200) {
        const token = refreshTokenResponse.data.token;
        localStorage.setItem('nfty-social-access-token', JSON.stringify(token));
        localStorage.setItem('nfty-social-refresh-token', JSON.stringify(refreshToken));
    }
}
