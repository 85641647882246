const logout = () => {
    localStorage.removeItem("nfty-social-user");
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("nfty-social-access-token"));
};


const authService = {
    logout,
    getCurrentUser,
};
  
export default authService;