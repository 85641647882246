import React from 'react';
import styles from './loader.module.css';

function Loader(props) {
    return (
        <div style={props.style} className={styles.loader}>
            Loading...
        </div>
    )
}

export default Loader;
