import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Tab,
    Tabs,
    Box,
    Toolbar
  } from "@material-ui/core";
import {Link, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home';
import Logo from './Logo';
import Following from '../pages/Following';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/Signup';
import { useHistory } from 'react-router-dom';
import {AccountProfile} from '../pages/AccountProfile';
import MetamaskSignup from '../pages/MetamaskSignup';


const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Navbar = () => {
  const history = useHistory();

  const handleLogout = () => {
    window.localStorage.clear();
    history.push("/signin");
  }

  return (
    <>
        <Switch>
            <Route exact path='/signin' component={SignIn} />
            <Route exact path='/signup' component={SignUp} />
            <Route exact path='/metamask-signup' component={MetamaskSignup} />
            <div>
                 { window.localStorage.getItem('nfty-social-refresh-token') ? <AppBar position="static">
                    <Toolbar>
                        <Logo />
                        <Box flexGrow={1} />
                        <Tabs   textColor="inherit">
                            <Tab label="Home" component={Link} to="/" />
                            <Tab label="Following" component={Link} to="/following" />
                            <Tab label="Logout" onClick={e => handleLogout()} component={Link} />
                        </Tabs>
                    </Toolbar>
                  </AppBar> : <></>
                }
                
                <Route exact path='/' component={Home} />
                <Route exact path='/following' component={Following} />
                <Route path='/account/:accId' component={AccountProfile} />
            </div>z
        </Switch>
    </>
  );
};

export default Navbar;