import React, {useContext, useEffect, useState} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from "axios";
import { dateConverter } from '../utils/date';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import AccountActivity from '../components/AccountActivity';
import { Button, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isLoggedIn, logout, refreshToken } from '../utils/auth';
import authHeader from '../services/auth-header';
import { AccountsContext } from '../App';
import { Typography } from '@material-ui/core';
import Tags from '../components/Tags';

const useStyles = makeStyles(theme => ({
  followingTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between'
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  centerDiv: {
    marginRight: 'auto',
  },
  activities: {
    marginLeft: theme.spacing(5)
  },
  followAccount: {
    // padding: '20px 15px 10px 15px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(5),
    justifyContent: 'space-evenly',
    alignItems: 'center',
    // boxShadow: '1px 1px 10px 1px silver'
  },
  btnText: {
    marginLeft: '3px',
    marginTop: '4px'
  }
}));


const AccountsActivities = (props) => {
  
  const { singleAccount, accountActivities } = props;
  const classes = useStyles();

  if (Object.keys(accountActivities).length > 0) {
    return (
      <>
        <h2>Account Activities:</h2>
        {
          Object.keys(accountActivities).map((key) => {
            return (
              <>
                <h3>{key}</h3>
                <div className={classes.activities}>
                  {
                    accountActivities[key].map(
                      (accountActivity, index) => <AccountActivity singleAccount={singleAccount} key={index} accountActivity={accountActivity} />
                    )
                  }
                </div>
              </>
            )
          })
        }
      </>
    );
  } else {
    return (<h3>Loading account activities...</h3>)
  }
}

export const AccountProfile = () => {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [accountActivities, setAccountActivities] = useState({});
  const [loading, setLoading] = useState(false);
  const [followingAccountList, setFollowingAccountList] = useContext(AccountsContext);
  const [isFollow, setIsFollow] = useState(false); 
  const [unfollow, setUnfollow] = useState(false); 

  /**
   * Handle follow an account
   * @param {*} accountId 
   */
  const handleFollowAccount = async(account) => {
    if(isLoggedIn()) {
      let authorizationHeader = authHeader();
      let addAccountAPIResponse;
      setLoading(true);
      try {
        addAccountAPIResponse = await axios({
          url: process.env.REACT_APP_API_SERVER_BASE_URL + "/api/v1/follow-account",
          method: 'POST',
          headers: authorizationHeader,
          data: {
            account
          }
        });
      } catch(err) {
        console.log("Error occured in add new account: ", err);
        try {
          await refreshToken();
          authorizationHeader = authHeader();
          addAccountAPIResponse = await axios({
            url: process.env.REACT_APP_API_SERVER_BASE_URL + "/api/v1/follow-account",
            method: 'POST',
            headers: authorizationHeader,
            data: {
              account
            }
          });
        } catch(err) {
          if(err.response.status === 403) logout(history);
        }
        setLoading(false);
      }
      if(addAccountAPIResponse.status === 200) {
        alert('Successfully account added');
        setIsFollow(true);
        setFollowingAccountList([...followingAccountList, account]);
        setLoading(false);
  
      } else {
        setLoading(false);
        alert(addAccountAPIResponse.data.message);
      }
      return ;
    }

    history.push({
      pathname: '/signup',
      state: { followAccount: account }
    });

  } 


  /**
   * Handle unfollow an account
   * @param {*} accountId 
   */
  const unfollowAccount = async(account) => {
    setLoading(true);
    let authorizationHeader = authHeader();

    let removeAccountResponse;
    try {
      removeAccountResponse = await axios({
        url: process.env.REACT_APP_API_SERVER_BASE_URL + '/api/v1/remove-following-account',
        method: 'POST',
        headers: authorizationHeader,
        data: {
          account
        }
      });
    } catch(err) {
      console.log("Error occured in remove account: ", err);
      if(err.response.status === 401) {
        try {
          await refreshToken();
          authorizationHeader = authHeader();
          removeAccountResponse = await axios({
            url: process.env.REACT_APP_API_SERVER_BASE_URL + '/api/v1/remove-following-account',
            method: 'POST',
            headers: authorizationHeader,
            data: {
              account
            }
          });
        } catch(err) {
          if(err.response.status === 403) logout(history);
        }
      }
      setLoading(false);
    }

    if(removeAccountResponse.status === 200) {
      alert('Successfully removed account');
      setLoading(false);
      setUnfollow(true);
    }
  } 

  useEffect(() => {

    const getCurrentlyFollowingAccounts = async () => {
      const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
      const followingAccountsAPIEndPoint = '/api/v1/currently-following-accounts';
      const endPoint = baseUrl + followingAccountsAPIEndPoint;
      const authorizationHeader = authHeader();

      try{
        let apiResponse = await axios({
          url: endPoint,
          method: 'POST',
          headers: authorizationHeader
        });
        if(apiResponse.data.count > 0) {
          const accountList = apiResponse.data.accounts;
          if(accountList.includes(params.accId)) {
            setIsFollow(true);
          }
          setFollowingAccountList(accountList);
        }
      }
      catch(err) {
        console.log("Error: ", err.response.data, err.response.status);
        if(err.response.status === 401){
          history.push('/signin');
        }
      }
    }
    if(isLoggedIn() && followingAccountList.length <= 0) {
      getCurrentlyFollowingAccounts();
    } 
    else if(followingAccountList.length > 0) {
      const found = followingAccountList.includes(params.accId);
      if(found) {
        setIsFollow(found);
      }
      else {
        setIsFollow(found);
      }
    }
  }, []);

  useEffect(() => {
    const getAllActivity = async() => {
      const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
      const followingAccountsActivityAPIEndPoint = '/api/v1/single-account-activity';
      const endPoint = baseUrl + followingAccountsActivityAPIEndPoint;

      try {
        const apiResponse = await axios({
          url: endPoint,
          method: 'POST',
          data: {
            accountAddresses: [params.accId]
          }
        });
        let activities = apiResponse.data.processedResponse;

        // Sort by date
        var orderedActivities = {};
        Object.keys(activities).sort(function(date1, date2) {
          return (dateConverter(date1) - dateConverter(date2));
        }).forEach(function(key) {
          orderedActivities[key] = activities[key];
        });
        setAccountActivities(orderedActivities);
      } catch(err) {
        console.log("Error: ", err.response.data, err.response.status);
        if(err.response.status === 401) {
          history.push('/signin');
        }
      }
    }
    getAllActivity();
  }, []);

  useEffect(() => {
    if(unfollow) {
      const updatedFollowAccounts = followingAccountList.filter(account => !account === params.accId);
      setFollowingAccountList(updatedFollowAccounts);
      setIsFollow(false);
      history.push('/following');
    }
  }, [unfollow]);

  return (
    <>
      <Container className={classes.container} component="main" maxWidth="sm">
      <CssBaseline />

        { Object.keys(accountActivities).length <= 0 ? 
          <></> : 
            <>
              {
                isFollow ? <div className={classes.followAccount}>
                  <div>{ params.accId }</div>
                  <Button onClick={e => unfollowAccount(params.accId)} variant="outlined" color="secondary" size="small">
                    { loading ? <CircularProgress size={20} thickness={4} color="secondary" /> : <FontAwesomeIcon icon="trash-can" /> }
                    <span className={classes.btnText}>Unfollow</span>
                  </Button>
                </div> :
                <div className={classes.followAccount}>
                  <div>{ params.accId }</div>
                  <Button onClick={e => handleFollowAccount(params.accId)} variant="outlined" color="primary" size="small">
                    { loading ? <CircularProgress size={20} thickness={4} color="primary" /> : <FontAwesomeIcon icon="circle-plus" size="lg" /> }
                    <span className={classes.btnText}>Follow</span>
                  </Button>
                </div>
              }

              <div style={{ display: 'flex'}}>
                <Typography variant="h6">Tags: </Typography>
                <Tags account={params.accId} />
              </div>
              
            
            </>
        }

        <div>
          {
            Object.keys(params.accId).length > 0 ?
              <AccountsActivities singleAccount={true} accountActivities={accountActivities} /> : 
                <></>
          }
        </div>
      </Container>
    </>
  )
}
