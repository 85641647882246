import React, { createContext, useEffect, useState} from 'react';
import authService from './services/auth-service';
import {createTheme} from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import Navbar from './components/Navbar';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCirclePlus, faSquarePlus, faCircleUser, faTrashCan } from "@fortawesome/free-solid-svg-icons";
library.add(faCirclePlus, faSquarePlus, faCircleUser, faTrashCan);

export const AccountsContext =createContext();

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b6'
    },
    secondary: purple
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700
  }
});

// https://www.youtube.com/watch?v=xIIJfmDnvPE&list=PL4cUxeGkcC9gjxLvV4VEkZ6H6H4yWuS58&index=7


function App() {
  const [followingAccountList, setFollowingAccountList] = useState([]); 
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = authService.getCurrentUser();

    if(user) {
      setCurrentUser(user);
    }
  });

  return (
    <AccountsContext.Provider value={[followingAccountList, setFollowingAccountList]} >
      <Navbar />
    </AccountsContext.Provider>
  );
}

export default App;
