import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    TextField, 
    Button, 
    makeStyles ,
    CircularProgress
} from '@material-ui/core';
import authHeader from '../services/auth-header';
import axios from "axios";
import { logout, refreshToken, isLoggedIn } from '../utils/auth';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    tags: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '0',
        listStyleType: 'none',
        flexWrap: 'wrap',
        textAlign: 'left'
    },
    tagItem: {
        margin: '3px 2px',
        border: '1px solid silver',
        padding: '4px 15px',
        minWidth: '20%',
        minHeight: '5%',
        textAlign: 'center',
        borderRadius: '30px',
        backgroundColor: '#E7E9EB'
    },
    addTag: {
        margin: '3px 2px',
        cursor: 'pointer'
    },
    loadingSpiner: {
        color: "#FFF",
        marginRight: "5px"
    }
}));

function Tags(props) {
    
    const { account } = props;
    const history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [tag, setTag] = useState('');
    const [tagList, setTagList] = useState([]);
    const [tagAdded, setTagAdded] = useState(false);
    const [tagError, setTagError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleTagInput = (event) => {
        setTagError(false);
        setTag(event.target.value);
    } 

    const addTag = async (account) => {
        if(!tag) {
            setTagError(true);
            return false;
        }
        else if(tag.length > 15 || tag.length < 3) {
            setTagError(true);
            setErrMsg("Tag must be of atleast 3 char or max 15 char");
            return false;
        }
        
        setLoading(true);
        let authorizationHeader = authHeader();
        let addTagResponse;

        try {
            addTagResponse = await axios({
              url: process.env.REACT_APP_API_SERVER_BASE_URL + '/api/v1/add-tag',
              method: 'POST',
              headers: authorizationHeader,
              data: {
                account: account,
                tag: tag
              }
            });

        } catch(err) {
            console.log("Error: ", err.response.data, err.response.status);
            
            if(err.response.status === 409) {
                alert(err.response.data.message);
                setTagError(true);
            }

            if(err.response.status === 401) {
                try {
                    await refreshToken();
                    authorizationHeader = authHeader();
                    addTagResponse = await axios({
                        url: process.env.REACT_APP_API_SERVER_BASE_URL + '/api/v1/add-tag',
                        method: 'POST',
                        headers: authorizationHeader,
                        data: {
                            account: account,
                            tag: tag
                        }
                    });
                } catch(err) {
                    if(err.response.status === 403) logout(history)
                }
            }
            setLoading(false);
        }

        if(addTagResponse.status === 200) {
            setOpen(false);
            setTagAdded(!tagAdded);
            setLoading(false);
            alert(addTagResponse.data.message);
        }
    }

    useEffect(() => {
        const getTags = async (account) => {
            let authorizationHeader = authHeader();
            let response;
            try {
                response = await axios({
                    url: process.env.REACT_APP_API_SERVER_BASE_URL + '/api/v1/tags-of-an-account',
                    method: 'POST',
                    headers: authorizationHeader,
                    data: {
                        account: account,
                    }
                });
            }
            catch(err) {
                console.log("Error while getting tags of an account: ", err);
                if(err.response.status === 401) {
                    try {
                        await refreshToken();
                        authorizationHeader = authHeader();

                        response = await axios({
                            url: process.env.REACT_APP_API_SERVER_BASE_URL + '/api/v1/tags-of-an-account',
                            method: 'POST',
                            headers: authorizationHeader,
                            data: {
                                account: account,
                            }
                        });
                    } catch(err) {
                        if(err.response.status === 401) logout(history);
                    }
                }
            }

            if(response.status === 200) {
                setTagList(response.data.tags);
            }
        }
        if(isLoggedIn()) getTags(account);

    }, [tagAdded]);

    return (
        <>
            <ul className={classes.tags}>
                {
                   tagList.map(tag => <li className={classes.tagItem}>{tag}</li>)
                }

                <div className={classes.addTag} onClick={handleClickOpen}>
                    <FontAwesomeIcon icon="circle-plus" size="2x" />
                </div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth="true">
                    <DialogTitle id="form-dialog-title">Add Tag</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            onChange={e => handleTagInput(e)}
                            margin="dense"
                            id="tag"
                            label={tagError && errMsg ? errMsg : 'Enter Tag'}
                            type="string"
                            error={tagError}
                            fullWidth
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button  onClick={e => addTag(account)} variant="contained" color="primary">
                        { loading ? <CircularProgress className={classes.loadingSpiner} size={20}  /> : <></>} Ok
                    </Button>
                    </DialogActions>
                </Dialog>
            </ul>
        </>
    )
}

export default Tags