import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white
      }
    },
    owned: {
        color: "#ee82ee"
    },
    notOwned: {
        color: "#ff6347"
    },
    bought: {
        color: 'green', 
        fontWeight: 'bold'
    },
    sold: {
        color: 'red', 
        fontWeight: 'bold'
    }
    
}));

function AccountActivity(props) {
    
    const classes = useStyles();

    const { accountActivity, singleAccount } = props;
    const dataLine = accountActivity.detaLine;
    const contractAddress = accountActivity.contractAddress;
    const tokenId = accountActivity.tokenID;
    const stillOwned = accountActivity.stillOwned;
    const boughtIndex = dataLine.indexOf('bought');
    const soldIndex = dataLine.indexOf('sold');

    if(boughtIndex !== -1) {
        return(
            <p>
                {
                    !singleAccount ? <Link
                        to={{
                            pathname: `account/${dataLine.slice(0, boughtIndex).trim()}`,
                            state:{}
                        }}
                        action="replace"              
                    >
                        {dataLine.slice(0, boughtIndex)} 
                    </Link> : <span>{dataLine.slice(0, boughtIndex)}</span>
                }
                <span className={classes.bought}>bought</span>
                { 
                    <a 
                        className={stillOwned ? classes.owned : classes.notOwned} 
                        href={process.env.REACT_APP_OPENSEA_ASSET_URL + contractAddress + "/" + tokenId} 
                        target="_blank" 
                        rel="noreferrer"
                    >
                        { dataLine.slice(boughtIndex + 'bought'.length, ) }
                    </a> 
                }
            </p>
        )
    }
    else if(soldIndex !== -1) {
        return(
            <p>
                { 
                    !singleAccount ? <Link
                        to={{
                            pathname: `account/${dataLine.slice(0, soldIndex).trim()}`,
                            state:{}
                        }}
                        action="replace"              
                    >
                        {dataLine.slice(0, soldIndex)}
                    </Link> : <span>{dataLine.slice(0, soldIndex)}</span>
                }
                <span className={classes.sold}>sold</span>
                <a 
                    className={ stillOwned ? classes.owned : classes.notOwned } 
                    href={process.env.REACT_APP_OPENSEA_ASSET_URL + contractAddress + "/" + tokenId} 
                    target="_blank" rel="noreferrer"
                >
                    { dataLine.slice(soldIndex + 'sold'.length, ) }
                </a>
            </p>
        )
    }
      
}

export default AccountActivity