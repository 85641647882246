import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import authHeader from '../services/auth-header';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import Container from "@material-ui/core/Container";
import AddAccount from '../components/AddAccount';
import { AccountsContext } from '../App';
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import { Table, TableHead, TableBody, TableCell, TableRow, TableContainer } from '@material-ui/core';
import SuggestingAccounts from '../components/SuggestingAccounts';
import Loader from '../components/Loader/Loader';
import { refreshToken, logout } from '../utils/auth';


const useStyles = makeStyles(theme => ({
  followingTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between'
  },
  spacingTop: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10)
  },
  suggestingAccountsList: {
    listStyle: 'none',
    margin: '0',
    padding: '0'
  },
  suggestingAccountsListItem: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    justifyContent: 'space-between',
    margin: '15px 0',
    boxShadow: '1px 1px 10px 1px silver',
    borderRadius: '10px',
    '&:hover': {
      boxShadow: '1px 3px 10px 1px silver'
   }
  },
  suggestingAccountsAddress: {
    marginTop: '8px'
  },
  suggestingAccountsIcon: {
    marginTop: '8px'
  },
  btnText: {
    marginTop: '4px',
    marginLeft: '4px'
  },
  btnTextSpacing: {
    margin: '0 4px'
  },
  loadingSpiner: {
    color: '#FFF'
  }
  
}));

function NotFound () {
  return <small>Not currently following any accounts!</small>
}


function Following() {
  const history = useHistory();
  const classes = useStyles();
   
  const [followingAccountList, setFollowingAccountList] = useContext(AccountsContext);
  const [addAccount, setAddAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accountRemoved, setAccountRemoved] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const getCurrentlyFollowingAccounts = async () => {
      const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
      const followingAccountsAPIEndPoint = '/api/v1/currently-following-accounts';
      const endPoint = baseUrl + followingAccountsAPIEndPoint;
      let authorizationHeader = authHeader();
      let apiResponse;
      try{
        apiResponse = await axios({
          url: endPoint,
          method: 'POST',
          headers: authorizationHeader
        });
      }
      catch(err) {
        console.log("Error: ", err.response.data, err.response.status);
        if(err.response.status === 401) {
          try {
            await refreshToken();
            authorizationHeader = authHeader();
            apiResponse = await axios({
              url: endPoint,
              method: 'POST',
              headers: authorizationHeader
            });
          } catch (err) {
            if(err.response.status === 403) logout(history);
          }
        }
      }

      if(apiResponse.data.count > 0) {
        setFollowingAccountList(apiResponse.data.accounts);
        setNotFound(false);
      }
      else{
        setFollowingAccountList([]);
        setNotFound(true);
      }

    }
    getCurrentlyFollowingAccounts();
  }, [addAccount, accountRemoved]);


  const handleRemoveFollowingAccount = async (event, account) => {
    event.preventDefault();
    setLoading(true);
    setSelectedAccount(account);
    let authorizationHeader = authHeader();
    let removeAccountResponse;
    try {
      removeAccountResponse = await axios({
        url: process.env.REACT_APP_API_SERVER_BASE_URL + '/api/v1/remove-following-account',
        method: 'POST',
        headers: authorizationHeader,
        data: {
          account
        }
      });
    } catch(err) {
      console.log("Error occured in remove account: ", err);
      if(err.response.status === 401) {
        try {
          await refreshToken();
          authorizationHeader = authHeader();
          removeAccountResponse = await axios({
            url: process.env.REACT_APP_API_SERVER_BASE_URL + '/api/v1/remove-following-account',
            method: 'POST',
            headers: authorizationHeader,
            data: {
              account
            }
          });
        } catch (err) {
          if(err.response.status === 403) logout(history);
        }
      }
      setLoading(false);
    }

    if(removeAccountResponse.status === 200) {
      alert('Successfully removed account');
      setAccountRemoved(!accountRemoved);
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Container className={classes.spacingTop} component="main" maxWidth='sm'>
        
          <div className={classes.followingTitleContainer}>
            <h2>Currently Following</h2>
            <h2>Following #{followingAccountList.length}</h2>
          </div>
            {
              notFound ? <NotFound /> : followingAccountList.length > 0 ?
                <TableContainer>
                <Table size="small" padding='normal'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>No.</TableCell>
                      <TableCell align='center'>Account Address</TableCell>
                      <TableCell align='center'>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      followingAccountList.map((account, index) => <TableRow>
                        <TableCell align='center'>{index + 1}</TableCell>
                        <TableCell align='center'>{account}</TableCell>
                        <TableCell align='center'>
                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={e => handleRemoveFollowingAccount(e, account)}
                          >
                            {
                              loading && selectedAccount===account ? <CircularProgress className={classes.loadingSpiner} size={20} /> :
                                <FontAwesomeIcon icon="trash-can" />
                            }
                            <span className={classes.btnTextSpacing}>
                              Delete
                            </span>
                          </Button>
                        </TableCell>
                      </TableRow>)
                    }
                  </TableBody>
                </Table>
              </TableContainer> : <Loader />
            }
          <div className={classes.spacingTop}>
            <AddAccount addAccount={addAccount} setAddAccount={setAddAccount} />
          </div>
          <div className={classes.followingTitleContainer}>
            <h2>Suggesting Accounts</h2>
          </div>
          <SuggestingAccounts 
            addAccount={addAccount} 
            setAddAccount={setAddAccount} 
            accountRemoved={accountRemoved}
          />
      </Container>
    </React.Fragment> 
  );
}

export default Following;