import React, { useEffect, useState, useContext } from 'react';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import authHeader from '../services/auth-header';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { AccountsContext } from '../App';
import {dateConverter} from '../utils/date';
import { Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { ITEM_PER_PAGE } from '../utils/constants';
import AccountActivity from '../components/AccountActivity';
import SuggestingAccounts from '../components/SuggestingAccounts';
import AddAccount from '../components/AddAccount';
import { AESC, DESC } from '../utils/constants';
import { refreshToken } from '../utils/auth';
import { logout } from '../utils/auth';

const useStyles = makeStyles(theme => ({
  followingTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between'
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  centerDiv: {
    marginRight: 'auto',
  },
  spacingTop: {
    marginTop: theme.spacing(5)
  },
  activities: {
    marginLeft: theme.spacing(4)
  },
  activitiesTypes: {
    textAlign: 'right'
  }
}));

const NoAccountFound = () => {
  return (
    <React.Fragment>
      <p>You're not following any accounts, enter an account to follow</p>
    </React.Fragment>
  )
}

const AccountsActivities = ({ singleAccount, accountActivities }) => {
  
  const classes = useStyles();

  if (Object.keys(accountActivities).length > 0) {
    return (
      <>
        <h2>Account Activities:</h2>
        {
          Object.keys(accountActivities).map((key) => {
            return (
              <>
                <h3>{key}</h3>
                <div className={classes.activities}>
                  {
                    accountActivities[key].map (
                      (accountActivity, index) => <AccountActivity singleAccount={singleAccount} key={index} accountActivity={accountActivity} />
                    )
                  }
                </div>
              </>
            )
          })
        }
      </>
    );
  } else {
    return (<h3>Loading account activities...</h3>)
  }
}


function Home() {
  const history = useHistory();
  const classes = useStyles();
  const [followingAccountList, setFollowingAccountList] = useContext(AccountsContext);
  const [accountActivities, setAccountActivities] = useState('');
  const [updatedAccountActivities, setUpatedAccountActivities] = useState({});
  const [noAccountFlag, setNoAccountFlag] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [isSetTotalPages, setIsSetTotalPages] = useState(false);
  const start = (page - 1) * ITEM_PER_PAGE;
  const [addAccount, setAddAccount] = useState(false);
  const [order, setOrder] = useState(0);

  useEffect(() => {
    const jwtRefreshToekn = localStorage.getItem('nfty-social-refresh-token');
    if(!jwtRefreshToekn) {
      history.push('/signin');
    }
  }, []);
  
  useEffect(() => {
    /**
     * Get all currently following accounts
     */
    const getCurrentlyFollowingAccounts = async () => {
      const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
      const followingAccountsAPIEndPoint = '/api/v1/currently-following-accounts';
      const endPoint = baseUrl + followingAccountsAPIEndPoint;
      let authorizationHeader = authHeader();

      let apiResponse;
      try{
        apiResponse = await axios({
          url: endPoint,
          method: 'POST',
          headers: authorizationHeader
        });
      }
      catch(err) {
        console.log("Error: ", err.response.data, err.response.status);
        if(err.response.status === 401){
          // history.push('/signin');
          try {
            await refreshToken();
            authorizationHeader = authHeader();
            apiResponse = await axios({
              url: endPoint,
              method: 'POST',
              headers: authorizationHeader
            });
          } catch (err) {
            console.log("err.response.status: ", err.response.status);
            if(err.response.status === 403) logout(history);
          }
        }
      }

      if(apiResponse.data.count > 0) {
        setFollowingAccountList(apiResponse.data.accounts);
        setNoAccountFlag(false);
      } else {
        setNoAccountFlag(true);
      }
    }
    if(followingAccountList.length <= 0 ) {
      getCurrentlyFollowingAccounts();
    }
  }, [addAccount]);


  useEffect(() => {

    /**
     * Fetch all accounts activities
     */
    const getAllActivity = async () => {
      const baseUrl = process.env.REACT_APP_API_SERVER_BASE_URL;
      const followingAccountsActivityAPIEndPoint = '/api/v1/following-accounts-activity';
      const endPoint = baseUrl + followingAccountsActivityAPIEndPoint;
      let authorizationHeader = authHeader();
      let apiResponse;
      try {
          apiResponse = await axios({
            url: endPoint,
            method: 'POST',
            headers: authorizationHeader,
            data: {
              accountAddresses: followingAccountList
            }
          });
      }
      catch(err) {
        console.log("Error: ", err.response.data, err.response.status);
        if(err.response.status === 401) {
          try {
            await refreshToken();

            authorizationHeader = authHeader();
            apiResponse = await axios({
              url: endPoint,
              method: 'POST',
              headers: authorizationHeader,
              data: {
                accountAddresses: followingAccountList
              }
            });
          } catch (err) {
            if(err.response.status === 403) logout(history);
          }
        }
      }

      let activities = apiResponse.data.processedResponse;
      // Sort by date
      var orderedActivities = {};
      Object.keys(activities).sort(function(date1, date2) {
        return (dateConverter(date1) - dateConverter(date2));
      }).reverse().forEach(function(key) {
        orderedActivities[key] = activities[key];
      });
      setAccountActivities(orderedActivities);
      const activitiesLength = Object.keys(orderedActivities).length;
      const totalPageCount = Math.ceil(activitiesLength/ITEM_PER_PAGE);
      if(totalPageCount > 0) {
        setTotalPages(totalPageCount);
        setIsSetTotalPages(true);
      }
    }
    if(followingAccountList.length > 0) {
      getAllActivity();
    }
  }, [followingAccountList, history]);


  useEffect(() => {
    if(accountActivities){
      const activities = {};
      Object.keys(accountActivities).slice(start, start + ITEM_PER_PAGE).map(key => {
        return activities[key] = accountActivities[key];
      });
      setUpatedAccountActivities(activities);
    }
  }, [accountActivities, page, start]);

  useEffect(() => {
    let orderedActivities = {};
    const latestToOldest = () => {
      // Sort by date
      Object.keys(accountActivities).reverse().forEach(function(key) {
        orderedActivities[key] = accountActivities[key];
      });
    }

    const oldestToLatest = () => {
      Object.keys(accountActivities).reverse().forEach(function(key) {
        orderedActivities[key] = accountActivities[key];
      });
    }
    setAccountActivities(orderedActivities);

    if(Object.keys(accountActivities).length > 0) {
      order == DESC ? latestToOldest() : oldestToLatest()  ;
    }
  }, [order]);


  /**
   * Handle page changes
   * @param {*} event 
   * @param {*} value 
   */
  const handlePageChange = (event, value) => {
    setPage(value);
  }

  if(noAccountFlag) {
    return (
      <>
        <Container className={classes.container} component="main" maxWidth="sm">
        <CssBaseline />
          <div>
            <AddAccount addAccount={addAccount} setAddAccount={setAddAccount} />
          </div>
          <div>
            <h2>Suggesting Accounts</h2>
            <SuggestingAccounts addAccount={addAccount} setAddAccount={setAddAccount} />
          </div>
        </Container>
      </>
    )
  }

  return (
    <>
      <Container className={classes.container} component="main" maxWidth="sm">
      { Object.keys(accountActivities).length > 0 ? <div className={classes.activitiesTypes}>
          <select onChange={e => setOrder(e.target.value)}>
            <option value={DESC}>Latest to Oldest</option>
            <option value={AESC}>Oldest to Latest</option>
          </select>
        </div> : <></>
      }
      <CssBaseline />
          {
            Object.keys(followingAccountList).length > 0 ?
              <AccountsActivities singleAccount={false} accountActivities={updatedAccountActivities} /> : 
                <></>
          }
          {
            noAccountFlag ? 
            <NoAccountFound />:<></>
          }
        {
          isSetTotalPages ? <Grid className={classes.spacingTop} container justifyContent="center">
            <Pagination onChange={handlePageChange} count={totalPages} page={page} color="primary" />
          </Grid> : <></>
        }
      </Container>
    </>
    )
}

export default Home;
