import React, { useEffect, useState } from 'react';
import { 
    Grid, 
    Container, 
    CssBaseline, 
    Typography, 
    TextField, 
    Button 
} from '@material-ui/core';
import Logo from '../components/Logo';
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { validEmail } from '../utils/validate';

const useStyles = makeStyles(theme => ({
    "@global": {
      body: {
        backgroundColor: theme.palette.common.white
      }
    },
    field: {
      marginTop: 10,
      marginBottom: 5,
      display: 'block'
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
    //   marginTop: theme.spacing(3)
    },
    submit: {
      marginTop: theme.spacing(2)
    },
    formContainer: {
      marginTop: theme.spacing(10)
    },
    loadingSpiner: {
      color: "#FFF",
      marginRight: "10px"
    }
}));

function MetamaskSignup(props) {

    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    const [userNameError, setUserNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [signature, setSignature] = useState('');
    const [nonce, setNonce] = useState('');

    useEffect(() => {
        if(typeof location.state !== 'undefined') {
            setWalletAddress(location.state.walletAddress);
            setNonce(location.state.nonce);
            setSignature(location.state.signature);
        }
        else {
            history.push('/signin');
        }
    }, [location, history]);


    /**
     * Handle metamask signup
     * @param {*} event 
     * @returns 
     */
    const handleSignUp = async (event) => {
        event.preventDefault();
        let checkFlag = true;
        setLoading(true);

        if(userName === '') {
            setUserNameError(true);
            checkFlag = false;
        }
        if(email==='' || !validEmail(email)) {
            setEmailError(true);
            checkFlag = false;
        }
        
        if(!checkFlag) {
            setLoading(false);
            return;
        }

        const endPoint = process.env.REACT_APP_API_SERVER_BASE_URL + "/api/v1/login-with-metamask";
        try {
            const response = await axios.post(endPoint, {
                walletAddress: walletAddress,
                signature: signature,
                nonce: nonce,
                name: userName,
                email: email
            });

            if(response.status === 200) {
                setLoading(false);
                localStorage.setItem('nfty-social-access-token', JSON.stringify(response.data.token));
                localStorage.setItem('nfty-social-refresh-token', JSON.stringify(response.data.refreshToken));
                // alert(signInApiResponse.data.message);
                history.push('/');
            }
        } 
        catch(err) {
            console.log("Error in Metamask signup: ", err);
            setLoading(false);
        }
    }

    
    /**
     * Handle seeting user inputs
     * @param {*} event 
     */
    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        
        if(userNameError || emailError) {
            setEmailError(false);
            setUserNameError(false);
        }

        switch(name) {
            case 'userName':
                setUserName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            default:
                break;
        }
    }

    return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Logo />
            <div className={classes.formContainer}>
                <Typography component="h1" variant="h5">
                Metamask Sign up
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSignUp}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <TextField
                        onChange={e => handleInputChange(e)}
                        className={classes.field}
                        variant="outlined"
                        required
                        fullWidth
                        id="userName"
                        label="User Name"
                        name="userName"
                        autoComplete="off"
                        error={userNameError}
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        onChange={e => handleInputChange(e)}
                        className={classes.field}
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        error={emailError}
                    />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    { loading ? <CircularProgress size={25} className={classes.loadingSpiner} /> : <></> }
                    Complete Metamask Signup
                </Button>
                </form>
            </div>
        </Container>
    );
}

export default MetamaskSignup